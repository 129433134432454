.custom-search {
  min-width: 200px;
  height: 32px;
  input {
    height: 100%;
    &.ant-input {
      height: 32px !important;
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      border-color: #cdd3e3;
    }
  }
  .ant-input-search-button {
    height: 22px !important;
    position: absolute;
    right: 1px;
    top: 50%;
    border-top: none;
    border-bottom: none;
    bottom: 9px;
    transform: translateY(-50%);
    z-index: 10;
    border-left: solid 1px #cdd3e3;
    border-right: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: -4px;
  }
  .ant-input-affix-wrapper {
    height: 32px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-color: #cdd3e3;
    padding-top: 0;
    padding-bottom: 0;
    .ant-input {
      height: 30px !important;
    }
  }
  .ant-input-group-addon {
    background: transparent;
  }
}

.custom-group {
  label {
    font-size: 18px;
    font-weight: 600;
    color: $text-color;
    // margin-bottom: 16px;
  }
  .ant-select-selector {
    // height: 32px !important;
    border: 1px solid $input-border !important;
    border-radius: 4px !important;
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      line-height: 30px;
    }
    // .ant-select-selection-overflow {
    //   flex-wrap: initial;
    //   height: 29px;
    // }
  }
  .ant-select-multiple .ant-select-selection-item {
    height: 22px;
    align-items: center;
    .ant-select-selection-item-remove {
      display: flex;
    }
  }
  .ant-input-affix-wrapper {
    border: 1px solid $input-border;
    border-radius: 4px;
    height: 34px;
    padding: 0;
    padding-right: 11px;
  }
  .ant-checkbox-inner {
    border: 1px solid $input-border;
    border-radius: 4px;
  }
  .ant-form-item-label {
    font-size: 18px;
    font-weight: 400;
    color: $text-color;
    margin: 0;
    display: block;
    width: 100%;
    text-align: left;
    padding-bottom: 0;
    label {
      height: auto;
    }
  }
  input,
  .ant-picker {
    height: 51px;
    border-color: $input-border;
    border-radius: 4px;
    font-size: 18px;
    color: $text-color;
    padding: 6px 12px;
    width: 100%;
  }
  .ant-picker {
    input {
      padding: 0px 0px !important;
    }
  }
  textarea {
    border-color: $input-border;
    border-radius: 4px;
    font-size: 16px;
    color: $text-color;
    padding: 6px 12px;
    min-height: 50px;
    resize: none;
  }
  input,
  textarea {
    line-height: 1.2;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      border-color: $text-color;
    }
  }

  &.md {
    label {
      font-size: 14px;
      margin-bottom: 5px;
    }
    input,
    .ant-picker {
      height: 32px;
      padding: 6px 12px;
      font-size: 16px;
    }
  }
  &.sm {
    label {
      font-size: 15px;
      margin-bottom: 12px;
    }
    input,
    .ant-picker {
      height: 32px;
      padding: 6px 12px;
    }
  }
  &.count-indicator {
    input {
      padding-right: 38px;
    }
  }
  &.date-picker-wrapper {
    .ant-form-item-label {
      font-size: 16px;
    }
  }
  &--textarea-resize{
    .ant-form-item{
      textarea.ant-input{
        resize: none;
        height:32px;
        min-height:auto !important;
        overflow: hidden;
        line-height: 1.2;
      
    }
   
  }
}
}
::placeholder {
  color: $input-border;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $input-border;
}

::-ms-input-placeholder {
  color: $input-border;
}

.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  min-height: 86px !important;
}

// checkbox
.ant-radio-checked {
  .ant-radio-inner{
    border-color: $primary;
    &::after{
background-color: $primary;
    }
  }
}
.ant-radio-inner::after,
.ant-radio-checked::after{
  border-color: $primary;
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
  border-color: $primary;
}
.ant-checkbox-inner {
  width: 24px;
  height: 24px;
}
.ant-checkbox-checked::after {
  border-color: $primary;
}
.ant-checkbox-inner::after {
  top: 46%;
  left: 26%;
  display: table;
  width: 6.714286px;
  height: 14.142857px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary;
  border-color: $primary;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
  font-size: 14px;
  color: $text-color;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primary;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: $primary;
}
.title-count {
  bottom: 0;
  text-align: right;
  right: 5px;
}
.ant-form-item .ant-input-textarea-show-count::after {
  position: relative;
  top: -21px;
  right: 10px;
  font-size: 12px;
  color: $gray-400;
}
.upload-content {
  .ant-upload-select {
    width: 100%;
    .ant-btn {
      border-radius: 4px;
      &:hover,
      &:focus {
        border-color: $input-border;
        color: inherit;
      }
    }
  }
}
.ant-switch-checked {
  background: $primary;
}

.lg-radio {
  .ant-radio-inner::after {
    width: 17px !important;
    height: 17px !important;
  }
  .ant-radio-inner {
    width: 25px;
    height: 25px;
  }
}
.atlms__search-input {
  .ant-input-affix-wrapper {
padding: 0;
input {
  height: 44px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding-left: 30px
}
  }

  .ant-input-search-button {
      height: 44px;
      border-top-right-radius: 30px !important;
      border-bottom-right-radius: 30px !important;
      width: 73px;
      font-size: 16px;
      span {
          font-size: 24px;
      }
  }
  }

.atlms__label-required {
  color: #2554da;
}


@media only screen and (max-width: 767px) {
  .custom-search {
    min-width: 240px;
  }
}
@media only screen and (max-width: 575px) {
  .custom-group {
    .ant-select {
      width: 100%;
    }
  }
}
