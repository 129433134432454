#login-container{
background: #FFFFFF;
}
// .hero {
//   color: #fff;
//   padding: 2em;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: relative;
//   overflow: hidden;

// }

.login-form {
  width: 100%;
  width: 360px;
  margin: 0 auto;
  // background-color: #fff;
  padding: 30px;
  // box-shadow: 0px 25px 18px 0px rgba(16, 5, 24, 0.11);
  .custom-input{
    // border-radius: 32px;
    border: 1px solid #D1D1D1;
    height: 42px;
  }
h2{
  font-weight: 700;
  font-size: 26px;
  color: rgba(0, 0, 0, 0.54);
}
}
.custom-input:focus, .custom-input:hover {
    border: 1px solid #47e5b9 !important;
}

.logo__brand {
  width: 100%;
  max-height: 40px;
}

.hero_text {
  position: relative;
  z-index: 10;
}
.green-circle{
  display: flex;
    align-items: center;
    justify-content: center;
  height: 130vh;
  width: auto;
  background: linear-gradient(
180deg
, #4EEDB4 0%, #38D5C7 100%);
  box-shadow: 4px 0px 10px rgb(0 0 0 / 25%);
  border-radius: 50%;
  margin-top: -15vh;
  margin-left: -30vh;
}
.login-bg{
  overflow: hidden;
    height: 100vh;
    padding-right: 20px;
}
.white-circle{
  height: 75vh;
  border-radius: 50%;
  width: 75vh;
  margin-left: -15vh;
  background: #FFFFFF;
box-shadow: 4px 0px 10px rgba(0, 0, 0, 0.25);
display: flex;
    align-items: center;
    justify-content: center;
img{
  max-width: 80%;
  max-height: 200px;
}
}
@media only screen and (max-width: 992px) {
  .white-circle {
    height: 65vh;
    border-radius: 50%;
    width: 65vh;
    margin-left: -5vh;
    img{
      max-height: 100px;
    }
}
}