.ant-btn-primary {
  // background-color: $primary;
  color: $white;
  border-color: $primary;
  font-size: 15px;
  font-weight: 500;
background: $primary-gradient;
box-shadow: 0px 2px 4px rgba(177, 177, 177, 0.25);
border-radius: 8px;
  &.md {
    height: 38px;
  }
  &.sm {
    font-size: 13px;
    font-weight: 400;
  }
  &.xs {
    font-size: 12px;
    font-weight: 400;
    padding: 2px 8px;
    height: auto;
  }
  &:hover,
  &:focus,
  &:active {
    background: $primary-gradient;
    border-color: darken($primary, 8%);
  }
}
.ant-btn-secondary {
  background-color: $white;
  color: $primary;
  border-color: $white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 8px;
  &.md {
    height: 38px;
  }
  &.sm {
    font-size: 13px;
    font-weight: 400;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: darken($white, 8%);
    border-color: darken($white, 8%);
    color: $primary;
  }
}
.btn-link-primary {
  color: $primary;
  font-style: 16px;
  font-weight: 300;
  &:hover {
    color: darken($primary, 8%);
  }
}

.ant-btn-background-ghost {
  border-color: $primary !important;
  color: $primary !important;
  &.sm {
    font-size: 13px;
    font-weight: 400;
  }
  &:hover {
    border-color: darken($primary, 8%);
    color: darken($primary, 8%);
  }
  &.ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
    background: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
  }
  .ant-btn-loading-icon {
    display: flex;
    .anticon {
      padding-right: 0;
    }
  }
}

.upload-button {
  font-size: 13px;
}
.white-space-nowrap {
  white-space: nowrap;
}
.btn-link {
  button {
    text-decoration: underline !important;
    color: $primary !important;
    font-weight: 500;
    &:hover {
      color: $primary !important;
    }
  }
}

.popover-footer {
  button {
    height: 26px !important;
    padding: 4px 8px !important;
    line-height: normal !important;
    &.remove-btn {
      background-color: #c9c9c9 !important;
      border-color: #c9c9c9 !important;
      color: $white !important;
      &:hover {
        background-color: darken(#c9c9c9, 8%) !important;
      }
    }
  }
}
a.underline-none {
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}
.ant-btn-circle{
  border-radius: 50%;
}
.ant-btn > .anticon{
  vertical-align: middle;
}