.ant-modal-footer {
    .ant-btn {
border-color: $primary;
color: $primary;
display: inline-block;
vertical-align: middle;
&.ant-btn-primary {
    color: #fff;
}
    }
}


.ant-modal-body {
    padding: 16px 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
}
.attendee-image {
    height: 40px;
    display: inline-block;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &.sm{
        height: 25px;
        width: 25px;
    }
}
.ant-select-selector{
   .attendee-image{
       margin-top: -6px;
       img {
        object-fit: contain;
    }
   }
}
.ant-select-item-option-content{
    .attendee-image{
        img {
         object-fit: contain;
     }
}
}
.ant-input[disabled]{
    color: rgb(0 0 0 / 40%) ;
}