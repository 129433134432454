.ant-layout-header {
  height: 64px;
  padding: 18px 16px;
  line-height: normal;
}
#header {
  position: relative;
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
  z-index: 10;
  .logout {
    div {
      &.ant-popover-open {
        color: $primary;
      }
    }
  }
}
.logo-container {
  img {
    max-height: 30px;
  }
}

.userinfo {
  p {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    color: $text-color;
    margin-right: 14px;
    span {
      display: block;
      font-size: 15px;
      font-style: italic;
    }
  }
}
.header-menu {
  display: flex;
  padding: 0 !important;
  margin: 0 12px 0 0 !important;
  li {
  list-style: none;
  padding-left: 32px;
  a {
      color: #7A8097;
      font-weight: 500;
      &:hover,
      &:focus,
      &:active {
          color: $primary;
          text-decoration: underline;
          
      }
  }
  }
}
@media only screen and (max-width: 767px) {
  .userinfo {
    display: none;
  }
}

