$base-font: 16px;
$font-family-base: "Roboto", sans-serif;

// Custom
$primary-gradient:linear-gradient(180deg, #4EEDB4 0%, #38D5C7 100%);
$padding-x: 34px;
$primary: #4ad4c6;
$primary-invert: #ffffff;
$secondary: #1b3d6e;
$secondary-invert: #ffffff;
$text-color: #1f2436;
$white: #fff;
$dark-pink:#CD47A2;
$blue:#5D63DC;
$red:#FE6B77;
$green:#13CD3C;
$yellow:#FFCE00;
$gray:#A7A7A7;
$border-radius: 8px; 
// Colors
$body-bg: #f8f9fa;
$body-color: #1f2436;
$input-border: #cdd3e3;
$gray-100: #f4f5f7;
$gray-200: #e5e7ea;
$gray-300: #adadad;
$gray-400: #525660;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 1.25;
$h2-font-size: $font-size-base * 1.2;
$h3-font-size: $font-size-base * 1;
$h4-font-size: $font-size-base * 0.875;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: (
      $spacer * 0.75,
    ),
    4: $spacer,
    5: (
      $spacer * 1.25,
    ),
    6: (
      $spacer * 1.5,
    ),
    7: (
      $spacer * 1.75,
    ),
    8: (
      $spacer * 2,
    ),
  ),
  $spacers
);

body {
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}
// support files
@import "common/support/support";

@import "common/header/header";
// bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
//main pages
@import "pages/login";
@import "pages/password";
//plugins
@import "plugins/input", 
        "plugins/buttons",
        "plugins/modal",
        "plugins/table",
        "plugins/card";
