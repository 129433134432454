html,
body,
#root,
.ant-layout {
  height: 100%;
}
.ant-layout {
  background: $body-bg;
}
.icon-12,.icon-20 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  height: 100%;

  svg {
    display: block;
    width: 100%;
    fill: currentColor;
    height: 100%;
  }
}

.icon-8 {
  width: 8px;
  height: 8px;
}
.icon-10 {
  width: 10px;
  height: 10px;
}

.icon-11 {
  width: 11px;
  height: 11px;
}

.icon-12 {
  width: 12px;
  height: 12px;
}

.icon-13 {
  width: 13px;
  height: 13px;
}

.icon-14 {
  width: 14px;
  height: 14px;
}

.icon-15 {
  width: 15px;
  height: 15px;
}

.icon-16 {
  width: 16px;
  height: 16px;
}

.icon-18 {
  width: 18px;
  height: 18px;
}

.icon-20 {
  width: 20px;
  height: 20px;
}

.icon-24 {
  width: 24px;
  height: 24px;
}

.icon-28 {
  width: 28px;
  height: 28px;
}

.icon-32 {
  width: 32px;
  height: 32px;
}

.icon-40 {
  width: 40px;
  height: 40px;
}

.icon-60 {
  width: 60px;
  height: 60px;
}

.icon-80 {
  width: 80px;
  height: 80px;
}

.icon-90 {
  width: 90px;
  height: 90px;
}

.icon-100 {
  width: 100px;
  height: 100px;
}

.icon-150 {
  width: 150px;
  height: 150px;
}

.svg-icon-hide {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.body-container {
  background-color: #eef1f8;
  padding: 16px 16px;
  overflow: auto;
  > * {
    height: 100%;
  }
}
.custom-container {
  width: 100% !important;
  max-width: 1376px !important;
  margin: 0 auto;
  padding: 0 16px;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
/* Let's get this party started */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background: #b2b2b2;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 20px;
}
.main-container {
  > div {
    > div {
      overflow: auto;
      margin-bottom: 30px;
    }
  }
}

.min-w-0 {
  min-width: 0;
}

.disabled{
  opacity: 0.3;
}

.labelText .ant-input-disabled,.ant-input-suffix {
  color: black;
}
.add-btn-style{
  margin-left: 20px;
}
.ant-input-search-button{
  display: flex;
    align-items: center;
    justify-content: center;
}