.ant-card-body {
    padding: 10px 15px 0px;
}

.ant-card-head {
    padding: 0px 15px;
    border-bottom: 1px solid #ececec;
}

.ant-card-head-title {
    padding: 11px 0;
}

.ant-tag {
    border-radius: 4px;
}

.responsive-card {
    border-radius: 8px;
}

.red-card {
    background-color: #FFF1F0;
    border: 1px solid #FFA39E;

    .ant-card-head {
        padding: 0px 15px;
        border-bottom: 1px solid #fcd8d6;
    }
}

.service-label {
    width: 110px;
    display: inline-block;
    color: #6C6E72;
    font-weight: 500;
}

.service-detail {
    color: #040404;

}

.ant-tag {
    font-size: 13px;
}

.ant-tag-cyan {
    background-color: #E6FFFB;
    border: none;
}
.sso-monitoring-cyan{
    color: #18bbaa;
    background: #E6FFFB;
    border-radius: 6px;
}