.sso-login-pwd{
    display: flex;
    justify-content: center;
    align-items: center;

    .sso-title {
        font-weight: 700;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.54);
    }

}

.box {
    width: 400px;
    margin: auto;
    padding: 30px;
    border-radius: 3px;
}

.sso-title {
    font-weight: 700;
    font-size: 26px;
    color: rgba(0, 0, 0, 0.54);
}

.sso-cancel-btn {
    height: 38px;
    border-radius: 8px;
    // border: 1px solid #47e5b9;  
    &:hover,
    &:focus,
    &:active {

      border-color: #47e5b9;
      color: #47e5b9;
    }
}


.password-bg {
    overflow: hidden;
    height: 100vh;
    padding-right: 20px;
}
.ant-btn:focus .ant-btn:hover {
    background: #fff;
    border-color: #47e5b9;
}

.custom-input {
    border-radius: 8px;
    border: 1px solid #D1D1D1;
    height: 42px;
}
.custom-input:hover {
    border: 1px solid #47e5b9;
}